import Vue from 'vue'
import { Button,Message, Dialog, Input, Form, FormItem } from 'element-ui';

// **************element UI库

Vue.use(Button)
Vue.use(Dialog)
Vue.use(Input)
Vue.use(Form);
Vue.use(FormItem);
Vue.prototype.$message = Message;
// **************