import i18n from "../i18n/index"; // 引入i18n实例
// 生成16位随机唯一id
export const RandomId = (n) => {
  var str = ['0','1','2','3','4','5','6','7','8','9','A','B','C','D','E','F','G','H','I','J','K',
   'L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
  var res = '';
  for (var i = 0; i < n; i++) {
    var id = Math.ceil(Math.random() * 35);
    res += str[id];
  }
  return res;
}
// localStorage的修改
export function localSet(key, value) {
  localStorage.setItem(key, JSON.stringify(value))
}
// localStorage的获取值
export function localGet(key) {
  return JSON.parse(localStorage.getItem(key))
}
// localStorage的删除
export function localDelect(key) {
  localStorage.removeItem(key)
}
// sessionStorage的修改
export function sessionSet(key, value) {
  sessionStorage.setItem(key, JSON.stringify(value))
}
// sessionStorage的取值
export function sessionGet(key) {
  return JSON.parse(sessionStorage.getItem(key))
}
// sessionStorage的删除
export function sessionDelect(key) {
  sessionStorage.removeItem(key)
}
function checkTime(i){ //将0-9的数字前面加上0，例1变为01 
  if(i<10) { 
    i = "0" + i; 
  } 
  return i; 
}
export function countDownTimer(value){ 
  let leftTime = parseInt(value)
  // console.log('leftTime',leftTime)
  // let leftTime = (new Date(year,month-1,day,hour,minute,second)) - (new Date()); //计算剩余的毫秒数 
  let days = parseInt(leftTime / 1000 / 60 / 60 / 24 , 10); //计算剩余的天数 
  let hours = parseInt(leftTime / 1000 / 60 / 60 % 24 , 10); //计算剩余的小时 
  let minutes = parseInt(leftTime / 1000 / 60 % 60, 10);//计算剩余的分钟 
  let seconds = parseInt(leftTime / 1000 % 60, 10);//计算剩余的秒数 
  // days = checkTime(days);
  // hours = checkTime(hours);
  // minutes = checkTime(minutes);
  // seconds = checkTime(seconds);
  // let daytime = days+"天" + hours+"小時" + minutes+"分鐘"+seconds+"秒";
  let daytime = ''
  if(days !== 0){
    daytime =daytime + days+i18n.tc('common_day')
  }
  if(hours !== 0){
    daytime = daytime +hours+i18n.tc('common_hour')
  }
  if(minutes !== 0){
    daytime = daytime +minutes+i18n.tc('common_minute')
  }
  daytime = daytime + seconds+i18n.tc('common_second')
  // let daytime = days+i18n.tc('common_day') + hours+i18n.tc('common_hour') + minutes+i18n.tc('common_minute')+seconds+i18n.tc('common_second');
  return daytime
}
export function countDownDelctTimer(value){ 
  let leftTime = parseInt(value)
  // console.log('leftTime',leftTime)
  // let leftTime = (new Date(year,month-1,day,hour,minute,second)) - (new Date()); //计算剩余的毫秒数 
  let hours = parseInt(leftTime / 1000 / 60 / 60 % 24 , 10); //计算剩余的小时 
  let minutes = parseInt(leftTime / 1000 / 60 % 60, 10);//计算剩余的分钟 
  let seconds = parseInt(leftTime / 1000 % 60, 10);//计算剩余的秒数 
  // hours = checkTime(hours);
  // minutes = checkTime(minutes);
  // seconds = checkTime(seconds);
  // let daytime = hours+"小時" + minutes+"分鐘"+seconds+"秒";
  let daytime = ''
  if(hours !== 0){
    daytime = daytime +hours+i18n.tc('common_hour')
  }
  if(minutes !== 0){
    daytime = daytime +minutes+i18n.tc('common_minute')
  }
  daytime = daytime + seconds+i18n.tc('common_second')
  // let daytime = hours + i18n.tc('common_hour') + minutes + i18n.tc('common_minute') + seconds + i18n.tc('common_second');
  
  return daytime
} 

