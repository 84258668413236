export function adClickNum1(){
  if(window.gtag){
    window.gtag('event', '广告点击总次数', {
      'event_category': 'button',
      'event_label': '广告点击总次数',
    });
  }
}
export function adClickNum2(){
  if(window.gtag){
    window.gtag('event', '广告点击总次数_首页', {
      'event_category': 'button',
      'event_label': '广告点击总次数_首页',
    });
  }
}
export function adClickNum3(){
  if(window.gtag){
    window.gtag('event', '广告点击总次数_投递成功', {
      'event_category': 'button',
      'event_label': '广告点击总次数_投递成功',
    });
  }
}
export function adClickNum4(){
  if(window.gtag){
    window.gtag('event', '广告点击总次数_已投递提醒', {
      'event_category': 'button',
      'event_label': '广告点击总次数_已投递提醒',
    });
  }
}
// ************************首次进来的语言（第二次新增）
export function enterHK(){
  if(window.gtag){
    window.gtag('event', '繁体中文打开次数', {
      'event_category': 'enter',
      'event_label': '繁体中文打开次数',
    });
  }
}
export function enterJP(){
  if(window.gtag){
    window.gtag('event', '日语打开次数', {
      'event_category': 'enter',
      'event_label': '日语打开次数',
    });
  }
}
export function enterEN(){
  if(window.gtag){
    window.gtag('event', '英语打开次数', {
      'event_category': 'enter',
      'event_label': '英语打开次数',
    });
  }
}
// ************************手动切换语言（第二次新增）
export function selectEN(){
  if(window.gtag){
    window.gtag('event', '手动切换英语次数', {
      'event_category': 'button',
      'event_label': '手动切换英语次数',
    });
  }
}
export function selectJP(){
  if(window.gtag){
    window.gtag('event', '手动切换日语次数', {
      'event_category': 'button',
      'event_label': '手动切换日语次数',
    });
  }
}
export function selectHK(){
  if(window.gtag){
    window.gtag('event', '手动切换繁体中文次数', {
      'event_category': 'button',
      'event_label': '手动切换繁体中文次数',
    });
  }
}
// ************************点击选择性别按钮（第二次新增）
export function clickSelectMan(){
  if(window.gtag){
    window.gtag('event', '点击男士的次数', {
      'event_category': 'button',
      'event_label': '点击男士的次数',
    });
  }
}
export function clickSelectLady(){
  if(window.gtag){
    window.gtag('event', '点击女士的次数', {
      'event_category': 'button',
      'event_label': '点击女士的次数',
    });
  }
}
// ************************递交手机号码页面（第二次新增）
export function clickSelectCountryNum(){
  if(window.gtag){
    window.gtag('event', '点击切换国家代码的次数', {
      'event_category': 'button',
      'event_label': '点击切换国家代码的次数',
    });
  }
}
export function clickPhoneInputNum(){
  if(window.gtag){
    window.gtag('event', '点击手机号码输入框的次数', {
      'event_category': 'button',
      'event_label': '点击手机号码输入框的次数',
    });
  }
}
export function clickPhoneInputSuccessNum(){
  if(window.gtag){
    window.gtag('event', '输入正确手机号码的次数', {
      'event_category': 'button',
      'event_label': '输入正确手机号码的次数',
    });
  }
}
export function clickPhoneInputFailNum(){
  if(window.gtag){
    window.gtag('event', '输入错误手机号码的次数', {
      'event_category': 'button',
      'event_label': '输入错误手机号码的次数',
    });
  }
}
export function clickGetPhoneCodeNum(){
  if(window.gtag){
    window.gtag('event', '点击获取验证码的次数', {
      'event_category': 'button',
      'event_label': '点击获取验证码的次数',
    });
  }
}
export function clickPhoneCodeInputNum(){
  if(window.gtag){
    window.gtag('event', '点击验证码输入框的次数', {
      'event_category': 'button',
      'event_label': '点击验证码输入框的次数',
    });
  }
}
export function clickPhoneCodeInputSuccessNum(){
  if(window.gtag){
    window.gtag('event', '输入正确验证码的次数', {
      'event_category': 'button',
      'event_label': '输入正确验证码的次数',
    });
  }
}
export function clickPhoneCodeInputFailNum(){
  if(window.gtag){
    window.gtag('event', '输入错误验证码的次数', {
      'event_category': 'button',
      'event_label': '输入错误验证码的次数',
    });
  }
}
export function clickSubmitNum(){
  if(window.gtag){
    window.gtag('event', '点击递交的次数', {
      'event_category': 'button',
      'event_label': '点击递交的次数',
    });
  }
}
// ************************递交手机号码成功页面（第二次新增）
export function sentWaitNum(){
  if(window.gtag){
    window.gtag('event', '递交成功，排队中', {
      'event_category': 'button',
      'event_label': '递交成功，排队中',
    });
  }
}
export function shareWaitNum(){
  if(window.gtag){
    window.gtag('event', '邀请好友一起排队次数', {
      'event_category': 'button',
      'event_label': '邀请好友一起排队次数',
    });
  }
}
export function sentPairSuccessNum(){
  if(window.gtag){
    window.gtag('event', '递交成功，配对成功', {
      'event_category': 'button',
      'event_label': '递交成功，配对成功',
    });
  }
}
export function sharePairPhoneSuccessNum(){
  if(window.gtag){
    window.gtag('event', '复制手机号码次数', {
      'event_category': 'button',
      'event_label': '复制手机号码次数',
    });
  }
}
// ************************点击分享按钮
export function clickShareNum(){
  if(window.gtag){
    window.gtag('event', '按钮点击总次数_分享链接', {
      'event_category': 'button',
      'event_label': '按钮点击总次数_分享链接',
    });
  }
}
// ************************投递成功
export function deliverySuccessNum1(){
  if(window.gtag){
    window.gtag('event', '投递成功总次数', {
      'event_category': 'button',
      'event_label': '投递成功总次数',
    });
  }
}
export function deliverySuccessNum2(){
  if(window.gtag){
    window.gtag('event', '投递账号成功_女士', {
      'event_category': 'button',
      'event_label': '投递账号成功_女士',
    });
  }
}
export function deliverySuccessNum3(){
  if(window.gtag){
    window.gtag('event', '投递账号成功_男士', {
      'event_category': 'button',
      'event_label': '投递账号成功_男士',
    });
  }
}
export function deliverySuccessNum4(){
  if(window.gtag){
    window.gtag('event', '交换成功总次数', {
      'event_category': 'button',
      'event_label': '交换成功总次数',
    });
  }
}
// ************************投递失败
export function deliveryFailNum1(){
  if(window.gtag){
    window.gtag('event', '投递失败总次数', {
      'event_category': 'button',
      'event_label': '投递失败总次数',
    });
  }
}
export function deliveryFailNum2(){
  if(window.gtag){
    window.gtag('event', '投递失败次数_验证码错误', {
      'event_category': 'button',
      'event_label': '投递失败次数_验证码错误',
    });
  }
}
export function deliveryFailNum3(){
  if(window.gtag){
    window.gtag('event', '投递失败次数_手机号码错误', {
      'event_category': 'button',
      'event_label': '投递失败次数_手机号码错误',
    });
  }
}
export function deliveryFailNum4(){
  if(window.gtag){
    window.gtag('event', '投递失败次数_未知原因', {
      'event_category': 'button',
      'event_label': '投递失败次数_未知原因',
    });
  }
}
// ************************输入验证码次数
export function codeInputNum(){
  if(window.gtag){
    window.gtag('event', '输入验证码次数', {
      'event_category': 'button',
      'event_label': '输入验证码次数',
    });
  }
}
// ************************获取验证码次数
export function getCodeNum(){
  if(window.gtag){
    window.gtag('event', '获取验证码次数', {
      'event_category': 'button',
      'event_label': '获取验证码次数',
    });
  }
}
// ************************他人邀请次数
export function invitationOtherNum(){
  if(window.gtag){
    window.gtag('event', '他人邀请进入次数', {
      'event_category': 'button',
      'event_label': '他人邀请进入次数',
    });
  }
}