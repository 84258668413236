import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'matchmakerHome',
    component: () => import(/* webpackChunkName: "about" */ '../views/matchmaker.vue')
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/interactive',
    name: 'interactive',
    component: () => import(/* webpackChunkName: "about" */ '../views/interactive.vue')
  },
  {
    path: '/matchmaker',
    name: 'matchmaker',
    component: () => import(/* webpackChunkName: "about" */ '../views/matchmaker.vue')
  },
  {
    path: '/matter',
    name: 'matter',
    component: () => import(/* webpackChunkName: "about" */ '../views/matter.vue')
  },
  {
    path: '/delivery',
    name: 'delivery',
    component: () => import(/* webpackChunkName: "about" */ '../views/delivery.vue')
  },
  {
    path: '/deliverySuccess',
    name: 'deliverySuccess',
    component: () => import(/* webpackChunkName: "about" */ '../views/deliverySuccess.vue')
  },
  // {
  //   path: '/PrivacyPolicy',
  //   name: 'PrivacyPolicy',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/iframePDF.vue')
  // }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
