import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

Vue.use(VueApollo);

const env = {
  // development: 'https://api.staging-app.peek.com.hk/graphql',
  development: 'https://api.cupidoffice.com/graphql',
  // development: 'https://api.prod.peek.com.hk/graphql',
  production: 'https://api.cupidoffice.com/graphql'
}

// 这里的地址写后端所在电脑的IP和端口号
const httpLink = new HttpLink({
  uri: env[process.env.NODE_ENV],
});

//创建Apollo客户端
const apolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

export default new VueApollo({
  defaultClient: apolloClient,
})
