// 成语接龙的key值，因为没有登录，要保存到localStorage里面
export const IDIOM_KEY = 'IDIOM_KEY'
// 月老办事处性别，保存到localStorage里面
export const MATCHMAKER_GENDER = 'MATCHMAKER_GENDER'
// 月老办事处手机号码，保存到localStorage里面
export const MATCHMAKER_PHONE = 'MATCHMAKER_PHONE'
// 月老办事处手机区号，保存到localStorage里面
export const MATCHMAKER_AREA = 'MATCHMAKER_AREA'
// 月老办事处session_id，保存到localStorage里面
export const MATCHMAKER_SESSION_ID = 'MATCHMAKER_SESSION_ID'
// 月老办事处MATCHMAKER_LANGUAGE，保存到localStorage里面
export const MATCHMAKER_LANGUAGE = 'MATCHMAKER_LANGUAGE'
// 月老办事处国家简称，保存到localStorage里面
export const MATCHMAKER_AREA_COUNTRY = 'MATCHMAKER_AREA_COUNTRY'