import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueClipBoard from 'vue-clipboard2'
import './utils/directive'
import './utils/element'
import './utils/vant'
import i18n from "./i18n/index"; // 引入i18n实例
// 引入动画animate.css
import animated from 'animate.css'

Vue.use(animated)

import apolloProvider from './vue-apollo'
import './styles/index.scss'
// **************
// 复制按钮
Vue.config.productionTip = false
Vue.use(VueClipBoard)
// **************

// **************
// 头部标识，development为开发和测试环境的key,production为是生成环境key
const HeaderKeyOption = {
  // development: 'da2-z7j4trwfdvg4fjwz4hnz36uim4',
  // development: 'da2-z7j4trwfdvg4fjwz4hnz36uim4',
  development: 'da2-xa3jcyszbnhe7aph3d3neyaizu',
  production: 'da2-xa3jcyszbnhe7aph3d3neyaizu'
}
const HeaderKey = HeaderKeyOption[process.env.NODE_ENV]
Vue.prototype.$HeaderKey = HeaderKey;
// **************

new Vue({
  router,
  i18n,
  store,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
