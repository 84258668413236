import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./locale/en.json";
import zhHK from "./locale/zh-hk.json";
import jp from "./locale/jp.json";
import { localGet, localSet } from "../utils/index"
import { MATCHMAKER_LANGUAGE } from '@/utils/defaultValue'
import { enterHK,enterEN,enterJP } from '@/utils/gtagFun'
// 注册i18n
Vue.use(VueI18n);

// 语言包根据语言环境分类
const messages = {
  en,
  zhHK,
  jp
};
let lang = "en"
// 1、先从本地获取语言。2、跟随系统语言设置
// if(localGet(MATCHMAKER_LANGUAGE)){
//   lang = localGet(MATCHMAKER_LANGUAGE)
// }else{
//   let language =(navigator.language || navigator.browserLanguage).toLowerCase();
//   if(language.indexOf('zh')>-1){
//     lang = "zhHK"
//     localSet(MATCHMAKER_LANGUAGE,lang)
//     enterHK()
//   }else if(language.indexOf('ja')>-1 || language.indexOf('jp')>-1){
//     lang = "jp"
//     localSet(MATCHMAKER_LANGUAGE,lang)
//     enterJP()
//   }else{
//     lang = "en"
//     localSet(MATCHMAKER_LANGUAGE,lang)
//     enterEN()
//   }
// }
localSet(MATCHMAKER_LANGUAGE,lang)
enterEN()

// 通过选项创建 VueI18n 实例并导出
const i18n =  new VueI18n({
  locale: lang, // 设置当前语言环境，默认中文简体
  messages, // 设置语言环境对应信息
});
// 重新封装方法
export function $t(args) {
  return i18n.tc.call(i18n, args);
}
export default i18n

